define(['ajax'], function(a) {
  var b = {
    init: function() {
      var a = this;
      b.ajax(a)
    },
    ajax: function(c) {
      a(c.options.defer, {
        success: function(a, d) {
          b.updateContent(c.$el, a), b.evaluateChildren(c)
        },
        error: function(a, d, e) {
          b.removeRootNode(c.$el)
        }
      })
    },
    updateContent: function(a, b) {
      a.innerHTML = b
    },
    removeRootNode: function(a) {
      a.parentNode.removeChild(a)
    },
    evaluateChildren: function(a) {
      if (a.options.hasOwnProperty('childComponent'))
        for (var c = a.options.childComponent.split(','), d = c.length - 1; d >= 0; d--) b.createSandbox(a, c[d])
    },
    createSandbox: function(a, b) {
      a.sandbox.start([{
        name: b,
        options: a.options
      }])
    }
  };
  return {
    initialize: b.init
  }
});